var React = require('react')

var useTrigger = require('./useTrigger')

function useTriggerEffect (action, trigger, defaultValue) {
	var triggerValue = useTrigger(trigger, defaultValue)

	React.useEffect(() => action(triggerValue), [triggerValue])
}

module.exports = useTriggerEffect
