import React, { useEffect } from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import useTrigger from '../../../../../vendor/react-use-trigger/useTrigger'

import Config from './assets.json'
import IntroText from './IntroText'
import IntroBackground from './IntroBackground'
import ProfileNav from './ProfileNav'
import Globe from './assets/Globe'
import ScrollIndicator from './ScrollIndicator'
import styles from './app.module.scss'
import * as bp from './breakpoints'
import AboutPageTemplate from './AboutPageTemplate'

import './assets.vars.scss.erb'

import Menu from './Menu'
import * as events from './events'
import Video from './Video'

const scaleValue = (y, b, d = .0001) => ((y - b) * ((y - b) * d))

const App = () => {
	let y = Math.round(useScrollPosition(60))
	const vh = useWindowHeight()
	const vw = useWindowWidth()
	const history = useHistory()
	const location = useLocation()
	const profilePage = location.pathname.startsWith('/profiles')
	const mobile = vw < 800
	const pageHeightOverride = useTrigger(events.setPageHeight, 0)
	const showMenu = useTrigger(events.toggleMenu, true)
	// const [showMenu, setShowMenu] = useState(true)

	useEffect(() => {
		// if (location.pathname === '/')
		//   window.scrollTo(0, 0)

		// Dirty hack for SEO BS
		let title
		let description
		switch (location.pathname) {
			case '/':
				window.scrollTo(0, 0)
				title = 'SwatchOn Future of Fabric™ Contest of Emerging Designers'
				description = 'In 2020, SwatchOn gave selected emerging fashion design pioneers from around the world $1,000 worth of fabric to create one outstanding look. See the finalists.'
				break
			case '/about/contest':
				title = 'About The Contest | SwatchOn Future of Fabric™'
				description = 'This contest celebrates and supports emerging fashion design pioneers from around the world. $1,000 worth of fabric to create one outstanding look.'
				break
			case '/about/swatchon':
				title = 'About SwatchOn | SwatchOn Future of Fabric™'
				description = 'SwatchOn connects fashion brands around the globe to over 200,000 wholesale fabrics on our streamlined, members-only platform.'
				break
			case '/profiles':
				title = 'The 10 Contest Finalists | SwatchOn Future of Fabric™'
				description = 'See the SwatchOn emerging designer contest finalists, and how they conceptualized and created during lockdown, from sketching to fabric selection to draping.'
				break
			case '/profiles/andrew-coimbra':
      case '/profiles/andrew-coimbra/info':
      case '/profiles/andrew-coimbra/interview':
      case '/profiles/andrew-coimbra/work':
      case '/profiles/andrew-coimbra/renderings':
				title = 'Andrew Coimbra - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Andrew Coimbra is a Toronto based genderless ready-to-wear label launched in 2015. With a strong focus on simple yet powerful design'
				break
			case '/profiles/angus-tsui':
      case '/profiles/angus-tsui/info':
      case '/profiles/angus-tsui/interview':
      case '/profiles/angus-tsui/work':
      case '/profiles/angus-tsui/renderings':
				title = 'Angus Tsui - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Angus Tsui rides the boundaries of fashion and futurism, The designs are born from advocacy, creativity, and imagination'
				break
			case '/profiles/annaiss-yucra':
      case '/profiles/annaiss-yucra/info':
      case '/profiles/annaiss-yucra/interview':
      case '/profiles/annaiss-yucra/work':
      case '/profiles/annaiss-yucra/renderings':
				title = 'Annaiss Yucra - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Annaiss Yucra encourages a radical change in her society by considering the lack of creativity and exposure in the Peruvian fashion system.'
				break
			case '/profiles/annelijn-hartevelt':
      case '/profiles/annelijn-hartevelt/info':
      case '/profiles/annelijn-hartevelt/interview':
      case '/profiles/annelijn-hartevelt/work':
      case '/profiles/annelijn-hartevelt/renderings':
				title = 'Annelijn Hartevelt - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Annelijn Hartevelt’s fashion designs hold a creative on-going dialogue with the world of contemporary art and the beauty of nature.'
				break
			case '/profiles/florentina-leitner':
      case '/profiles/florentina-leitner/info':
      case '/profiles/florentina-leitner/interview':
      case '/profiles/florentina-leitner/work':
      case '/profiles/florentina-leitner/renderings':
				title = 'Florentina Leitner - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Florentina Leitner is an Austrian fashion and knitwear designer whose brand uplifts your mood with vibrant prints and colorful textiles.'
				break
			case '/profiles/jenn-lee':
      case '/profiles/jenn-lee/info':
      case '/profiles/jenn-lee/interview':
      case '/profiles/jenn-lee/work':
      case '/profiles/jenn-lee/renderings':
				title = 'Jenn Lee- Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist JENN LEE is an inspiring sustainability brand which seeks to unleash the wildness and the creativity within us all.'
				break
			case '/profiles/katrin-aasmaa':
      case '/profiles/katrin-aasmaa/info':
      case '/profiles/katrin-aasmaa/interview':
      case '/profiles/katrin-aasmaa/work':
      case '/profiles/katrin-aasmaa/renderings':
				title = 'Katrin Aasmaa - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Katrin Aasmaa\'s work prioritizes tailored silhouettes and eclectic use of colors and materials.'
				break
			case '/profiles/ns-gaia-sidharth-sinha':
      case '/profiles/ns-gaia-sidharth-sinha/info':
      case '/profiles/ns-gaia-sidharth-sinha/interview':
      case '/profiles/ns-gaia-sidharth-sinha/work':
      case '/profiles/ns-gaia-sidharth-sinha/renderings':
				title = 'N&S GAIA - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist N&S GAIA is a New Delhi based contemporary womenswear label which is rich in unique design and fabric techniques.'
				break
			case '/profiles/silpa-myno-macheda-and-mehreen-asma':
      case '/profiles/silpa-myno-macheda-and-mehreen-asma/info':
      case '/profiles/silpa-myno-macheda-and-mehreen-asma/interview':
      case '/profiles/silpa-myno-macheda-and-mehreen-asma/work':
      case '/profiles/silpa-myno-macheda-and-mehreen-asma/renderings':
				title = 'Śilpa - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist Śilpa is a contemporary streetwear brand which infuses western and south Asian culture with an artistic approach.'
				break
			case '/profiles/two-point-two-anvita-sharma':
      case '/profiles/two-point-two-anvita-sharma/info':
      case '/profiles/two-point-two-anvita-sharma/interview':
      case '/profiles/two-point-two-anvita-sharma/work':
      case '/profiles/two-point-two-anvita-sharma/renderings':
				title = 'TWO POINT TWO - Fashion Designer | SwatchOn Future of Fabric™'
				description = 'SwatchOn contest finalist TWO POINT TWO is a genderless, all-inclusive, sustainable, and anti-conformist fashion label.'
				break
			default:
				console.log('location: ', location)
				console.log('location.pathname: ', location.pathname)
				break
		}

		document.head.querySelector('title').text = title
		document.head.querySelector('meta[name="description"]').content = description
	}, [location.pathname])

	if (location.pathname === '/' && y >= bp.end) {
		setTimeout(() => history.push('/profiles'))
	}

	let height = bp.end + vh + 200

	if (pageHeightOverride !== 0) {
		if (pageHeightOverride === 'WINDOW_HEIGHT') {
			height = vh
		} else {
			height = pageHeightOverride
		}
	} else {
		if (profilePage) {
			if (mobile) {
				height = '4580px'
			} else {
				y = bp.end
				height = vh
			}
		}
	}

	const currentDesignerID = location.pathname.startsWith('/profiles') && location.pathname.replace('/profiles', '').replace('/', '').split('/')[0]

	return <div style={{ height }}>
		{showMenu && <Menu />}

		<Switch>
			<Route exact path="/">
				<ScrollIndicator y={y} show={bp.showScrollIcon} hide={bp.hideScrollIcon} />

				<div className={styles.logoContainer}>
					<div className={styles.logo} style={{
						transform: `scale(${
							bp.hideWelcomeText > y
								? 1
								: Math.max(1 - scaleValue(y, bp.hideWelcomeText, .000001), 0)
						})`,
					}}>
						<h1>
							<small>Welcome to</small>
							<br />
							<span>The Future of Fabric</span>
						</h1>
					</div>
				</div>

				<div>
					<IntroText
						html={Config.intro.intro_text[0].content}
						y={y} show={bp.showIntroText1} hide={bp.hideIntroText1}
					/>

					<IntroText
						html={Config.intro.intro_text[1].content}
						headline={Config.intro.intro_headlines[1].content}
						placement="right"
						y={y} show={bp.showIntroText2} hide={bp.hideIntroText2}
					/>

					<IntroText
						headline={Config.intro.intro_headlines[2].content}
						html={Config.intro.intro_text[2].content}
						placement="left"
						y={y} show={bp.showIntroText3} hide={bp.hideIntroText3}
					/>

					<IntroText
						headline={Config.intro.intro_headlines[3].content}
						html={Config.intro.intro_text[3].content}
						placement="right"
						y={y} show={bp.showIntroText4} hide={bp.hideIntroText4}
					/>
				</div>

				<Globe style={{ zIndex: 3 }} y={y} showAt={bp.showGlobe} hideLocations={bp.hideLocations} />

				<ProfileNav
					mobile={mobile}
					key={mobile + 'HOME_PAGE'}
					y={y}
					zIndex={3}
					page={false}
					currentDesignerID={location.pathname.replace('/profiles', '').replace('/', '').split('/')[0]}
				/>
			</Route>

			<Route path="/profiles">
				<ProfileNav
					mobile={mobile}
					key={mobile}
					y={y}
					zIndex={3}
					page={true}
					currentDesignerID={currentDesignerID}
				/>
			</Route>

			<Route path="/about/contest">
				<AboutPageTemplate
					y={y}
					sections={[
						Config.about_the_contest.designers,
						Config.about_the_contest.clo3d,
						Config.about_the_contest.influencers,
					]}
					id="about-the-contest"
					key="about-the-contest"
					backgroundPoster={Config.about_the_contest.background_poster}
					backgroundVideo={Config.about_the_contest.background_video}
					menu={Config.about_the_contest.menu}
				/>
			</Route>

			<Route path="/about/swatchon">
				<AboutPageTemplate
					y={y}
					sections={[
						Config.about_swatchon.technology_and_textiles,
						Config.about_swatchon.focused_on_the_future,
					]}
					id="about-swatchon"
					key="about-swatchon"
					backgroundPoster={Config.about_swatchon.background_poster}
					backgroundVideo={Config.about_swatchon.background_video}
					menu={Config.about_swatchon.menu}
				/>
			</Route>
		</Switch>

		{(location.pathname === '/' || location.pathname.startsWith('/profiles')) && (() => {
			if (location.pathname.startsWith('/profiles')) {
				y = bp.showProfiles
			}

			return <>
				<div id="intro-mask-video-container" style={{
					WebkitMaskSize: `${Math.min(1000, scaleValue(y, 0) + 10)}%`,
					zIndex:         2,
				}}>
					<Video
						poster={Config.intro.intro_videos[1].intro_mask_poster}
						src={Config.intro.intro_videos[1].intro_mask_video}
						type="application/x-mpegURL"
					/>
				</div>
			</>
		})()}

		{(location.pathname === '/') && (() => {
			return <>
				<IntroBackground y={y} playing={bp.introBackgroundVideoPlaying} paused={bp.introBackgroundVideoPaused} zIndex={1} />
			</>
		})()}
	</div>
}

export default App