/* eslint-disable react/prop-types */
import React from 'react'

// import Config from '../assets.json'

import styles from './IntroText.module.scss'
import RingTextBackground from './assets/RingTextBackground'

import { useWindowWidth } from '@react-hook/window-size'

// function ease(x) {
// 	// return 1 - (1 - x) * (1 - x)
// 	return x === 0 ? 0 : Math.pow(2, 10 * x - 10)
// }

const scaleIntroText = (vw, y, show, hide, placement) => {
	const inRange = 300
	const outRange = 300
	let opacity = 0
	const startScale = 7
	let scale = startScale
	const inStart = show - inRange
	const outStart = hide
	let x = placement === 'right' ? 500 : -500

	if (y > outStart) {
		const diff = y - outStart
		opacity = Math.max(1 - diff / outRange, 0)
		scale = Math.max(1 - diff / outRange, 0)
		const diff2 = 1 - opacity
		x = (-x) * (diff2)
	} else if (y > inStart) {
		const diff = y - inStart
		opacity = Math.min(diff / inRange, 1)
		scale = Math.max(startScale - opacity * (startScale - 1), 1)

		x = x * (1 - opacity)
	}

	return {
		opacity,
		transform: `scale(${scale}) translateX(${x}px)`,
		height:    '100%',
	}
}

const IntroText = ({ html, y, show, hide, placement = 'left', headline }) => {
	const vw = useWindowWidth()

	return <div className={styles.root + ' ' + styles[placement]}>
		<div style={scaleIntroText(vw, y, show, hide, placement)}>
			{headline && <div
				className={styles.headline}
				dangerouslySetInnerHTML={{ __html: headline }}
			/>}

			<div className={styles.content}>
				<div className={styles.inner} dangerouslySetInnerHTML={{ __html: html }} />
			</div>

			<RingTextBackground className={styles.ring} />
		</div>
	</div>
}

export default IntroText