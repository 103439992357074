/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'

import c from 'classnames'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { useMeasure } from 'react-use'
import clamp from 'lodash-es/clamp'

import styles from './WorkSlide.module.scss'
import workStyles from './Work.module.scss'


import IconChevronLeft from '../assets/IconChevronLeft'
import IconChevronRight from '../assets/IconChevronRight'
import DesignerInfoNav from '../DesignerInfoNav'
import IconInstagram from '../assets/IconInstagram'


const WorkSlide = ({ designer, navigating = false }) => {
	const list = designer.designer_work[0].images

	const MAX_INDEX = list.length - 1

	const back = (set, current) => () =>
		(current === 0)
			? set(MAX_INDEX)
			: set(current - 1)

	const next = (set, current) => () =>
		(current === MAX_INDEX)
			? set(0)
			: set(current + 1)

	const index = useRef(0)
	const cycle = useRef(true)
	const [stateIndex, setStateIndex] = useState(index.current)
	const [mx, setMx] = useState(0)
	const [ref, { width }] = useMeasure()
	const props = useSpring({ x: (stateIndex * -width) + mx })
	const large = true

	function setIndex(val) {
		index.current = val
		setStateIndex(val)
	}

	// Set the drag hook and define component movement based on gesture data
	const bind = useDrag(({ down, movement: [mx], /*direction: [xDir],*/ swipe: [xSwipe], /*distance,*/ cancel }) => {
		if (xSwipe !== 0) {
			// so far in my tests, this function gets invoked exactly 2 times when it is called,
			// so i alternate which time it is called
			if (cycle.current) {
				cycle.current = false

				index.current = clamp(Math.round(stateIndex - xSwipe), 0, MAX_INDEX)
				// prevIndex.current = index.current
				setStateIndex(index.current)
				setMx(0)
				cancel()
			} else {
				cycle.current = true
			}

			return
		} else
			setMx(down ? mx : 0)
	})

	return <div
		ref={ref}
		className={styles.root}
		style={{ opacity: navigating ? 0 : 1 }}
	>
		<div className={c(styles.arrows, { [styles.largeArrows]: large })}>
			{index.current != 0
				&& <IconChevronLeft className={styles.left} onClick={back(setIndex, stateIndex)} />}
			{index.current != MAX_INDEX
				&& <IconChevronRight className={styles.right} onClick={next(setIndex, stateIndex)} />}
		</div>

		<animated.div
			className={styles.slide}
			{...bind()}
			style={props}
		>
			{list.map((item) =>
				<div key={item} style={{ backgroundImage: `url(${item})` }} />,
			)}
		</animated.div>

		<DesignerInfoNav
			list={list.map((n, i) => i)}
			set={setIndex}
			current={stateIndex}
		/>

		<a className={workStyles.wornBy} href={designer.designer_work[0].model.instagram} target="_blank" rel="noreferrer">
			<IconInstagram className={workStyles.instagram} />

			<div>
				Worn by
				<div dangerouslySetInnerHTML={{ __html: designer.designer_work[0].model.name }} />
			</div>
		</a>
	</div>
}

export default WorkSlide
