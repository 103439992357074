/* eslint-disable react/prop-types */
import React from 'react'
import styles from './Profile.module.scss'

import { showProfiles } from './breakpoints'
import c from 'classnames'

import RingProfile from './assets/RingProfile'

const rad = d => d * (Math.PI/180)
// const deg = r => r / (Math.PI/180)

const rem = px => px / 16

const Profile = ({
	current,
	currentIndex,
	isNext,
	isPrev,
	isSelected,
	mobile,
	navigating,
	onClick,
	page,
	pos,
	profile,
	steps,
	transition,
	tw,
	y: pageY,
}) => {
	let x = 0
	let y = 0
	let size, zIndex
	let opacity = 1
	let transform = ''
	let pageOffset = Math.max(pageY - showProfiles, 0)
	let mobileScrollNameOpacity = 0

	if (mobile) {
		size = 1

		if (isSelected) {
			if (current) {
				size = 1.4
			}
		} else {
			size = 0.9
		}

		y = pos * 400

		if (!isSelected) {
			let mobileScrollNameOpacityY = y - 200

			if ((pageY + 100) > mobileScrollNameOpacityY) {
				mobileScrollNameOpacity = Math.min((pageY - mobileScrollNameOpacityY) / 100, 1)
			}

			let mobileScrollNameOpacityYBreak2 = 400 + 100

			if (pageY > mobileScrollNameOpacityY + mobileScrollNameOpacityYBreak2) {
				let length = 100

				mobileScrollNameOpacity = Math.max(
					(
						(mobileScrollNameOpacityY + mobileScrollNameOpacityYBreak2 + length) - pageY
					) / length,
					0,
				)
			}
		}

		let index = currentIndex === -1 ? 0 : currentIndex
		if (page) {
			y = y - pageY - (400*index)

			if (current) {
				y = y - 50
			} else if (isPrev) {
				y = y - 50
				size = 0.65
			} else if (isNext) {
				y = y + 30
				size = 0.65
			}
		} else {
			let diff = pageOffset
			y = y - 400
			opacity = (diff - 200) / 400

			if (pos != 1) {
				opacity = (diff - 500) / 400
			}
		}
		// }

		zIndex = 1
		transform = `translate(${rem(x)}rem, ${rem(y)}rem) scale(${size})`
	} else {
		let scale = Math.min(Math.max(pageOffset * 0.5, 0), 450)

		let p = tw

		let stepX = (rad(360 / steps) * (pos))
		let stepY = (rad(360 / steps) * (pos))

		let sin = Math.sin(stepX)
		let cos = Math.cos(stepY)

		x = (sin * scale)
		y = (cos * scale)
		// let angle = deg(stepX) % 360
		size = 1
		zIndex = 1

		if (current) {
			x = 0
			y = 0
			size = 8
			zIndex = 3
		} else if (isNext) {
			x = 600
			y = -300
			size = 2
			zIndex = 2
		} else if (isPrev) {
			x = -600
			y = 300
			size = 2
			zIndex = 2
		} else if (isSelected) {
			// const extra = 3
			// x = x * (1 + .2 * p) * extra
			// y = y * (0.5 + 0.5 * (1 - p)) - x / 3 * p * extra
			x = -999
			y = -999
			size = 2
			zIndex = 2
		} else /*if (page)*/ {
			x = x * (1 + .2 * p)
			y = y * (0.5 + 0.5 * (1 - p)) - x / 3 * p
			size = zIndex + ((sin + cos) / 3) * p
			zIndex = Math.round(size*100)
		}

		transform = `translate(${rem(x)}rem, ${rem(y)}rem) scale(${0.2 * size})`
	}

	return <RingProfile
		primaryColor={profile.primary_color}
		gradientStart={profile.gradient_color_start}
		gradientMiddle={profile.gradient_color_middle}
		gradientEnd={profile.gradient_color_end}
		className={c(styles.profile, { notransition: (!transition || (mobile && !isSelected)) })}
		style={{
			transform,
			zIndex,
			opacity: !isSelected || (current || isNext || isPrev) ? opacity : 0,
		}}
	>
		<div
			onClick={onClick}
			className={styles.profileContent}
			style={{ backgroundImage: (!current || navigating) && `url(${profile.profile})` }}
		>
			<div
				className={styles.mobileScrollName}
				style={{ opacity: mobileScrollNameOpacity }}>
				{profile.name}
			</div>
		</div>
	</RingProfile>
}

export default Profile
