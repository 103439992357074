/* eslint-disable react/prop-types */
import React from 'react'
import c from 'classnames'

import styles from './Page.module.scss'

const Page = ({ className, children }) =>
	<div className={c(styles.root, className)}>{children}</div>

export default Page
