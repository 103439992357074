/* eslint-disable react/prop-types */
import * as React from 'react'

const IconChevronRight = ({ onClick, className }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			aria-labelledby="icon-chevron-right"
			height="42.1"
			role="img"
			viewBox="0 0 22.8 42.1"
			width="22.8"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-chevron-right">Icon Chevron Right</title>
			<path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4" d="M1.2 1.2l19.9 19.9L1.2 40.9" />
		</svg>
	)
}

export default IconChevronRight
