/* eslint-disable react/prop-types */
import * as React from 'react'
import c from 'classnames'

import styles from './DesignerInfo.module.scss'
import IconInstagram from './assets/IconInstagram'
import IconWebsite from './assets/IconWebsite'

const DesignerInfo = ({ designer }) => {
	const info = designer.designer_info[0]

	return <div className={styles.root}>
		<div className={styles.inner}>
			<div
				className={c(
					styles.content,
					styles[designer.id],
					designer.id,
					styles['page-0'],
				)}
			>
				<div className={styles.headline} dangerouslySetInnerHTML={{ __html: info.headline }} />
				<div className={styles.copy} dangerouslySetInnerHTML={{ __html: info.copy }} />
			</div>
		</div>

		<div className={styles.websiteLinks}>
			{info.website && <a href={info.website} target="_blank" rel="noreferrer"><IconWebsite /></a>}
			{info.instagram && <a href={info.instagram} target="_blank" rel="noreferrer"><IconInstagram /></a>}
		</div>
	</div>
}

export default DesignerInfo
