/* eslint-disable react/prop-types */
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import c from 'classnames'

import styles from './DesignerNav.module.scss'

const DesignerNav = ({ designer, navigating }) =>
	<div className={c(styles.root, { [styles.navigating]: navigating })}>
		<NavLink
			className={styles.info}
			activeClassName={styles.selected}
			style={{ '--bgi': `url(${designer.profile})` }}
			to={`/profiles/${designer.id}/info`}
		/>

		<NavLink
			className={styles.video}
			activeClassName={styles.selected}
			style={{ '--bgi': `url(${designer.designer_interview.poster})` }}
			to={`/profiles/${designer.id}/interview`}
		/>

		<NavLink
			className={styles.work}
			activeClassName={styles.selected}
			style={{ '--bgi': `url(${designer.designer_work[0].images[0]})` }}
			to={`/profiles/${designer.id}/work`}
		/>

		<NavLink
			className={c(styles.renderings, designer.id)}
			activeClassName={styles.selected}
			style={{ '--bgi': `url(${designer.designer_renderings.poster})` }}
			to={`/profiles/${designer.id}/renderings`}
		/>
	</div>

export default DesignerNav
