/* eslint-disable react/prop-types */
import * as React from 'react'

const RingAboutMenuBackground = ({
	primaryColor = '#9279b2',
	gradientStart = '#8d55a4',
	gradientMiddle = '#9279b2',
	gradientEnd = '#8d55a4',
}) =>
	<svg
		aria-labelledby="ring-about-menu-background"
		height="340"
		role="img"
		viewBox="0 0 340 340"
		width="340"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title id="ring-about-menu-background">Ring About Menu Background</title>

		<circle
			cx="170.3"
			cy="170.3"
			r="145.3"
			opacity=".5"
			fill={primaryColor}
		/>

		<linearGradient id="RingAboutMenuBackground_gradient" x1="981.055" x2="808.933" y1="-482.719" y2="-236.903" gradientTransform="scale(-1 1) rotate(55 -146.374 -1117.752)" gradientUnits="userSpaceOnUse">
			<stop offset="0" stopColor={gradientStart} />
			<stop offset=".509" stopColor={gradientMiddle} />
			<stop offset="1" stopColor={gradientEnd} />
		</linearGradient>

		<circle cx="170" cy="170" r="145" fill="none" stroke="url(#RingAboutMenuBackground_gradient)" strokeMiterlimit="10" strokeWidth="10" />

		<path
			fill="#fff"
			d="M170 1c22.8 0 44.9 4.5 65.8 13.3 20.1 8.5 38.2 20.7 53.7 36.2 15.5 15.5 27.7 33.6 36.2 53.7 8.8 20.8 13.3 43 13.3 65.8 0 22.8-4.5 45-13.3 65.8-8.5 20.1-20.7 38.2-36.2 53.7-15.5 15.5-33.6 27.7-53.7 36.2-20.8 8.8-43 13.3-65.8 13.3-22.8 0-45-4.5-65.8-13.3-20.1-8.5-38.2-20.7-53.7-36.2C35 274 22.8 255.9 14.3 235.8 5.5 214.9 1 192.8 1 170c0-22.8 4.5-44.9 13.3-65.8C22.8 84.1 35 66 50.5 50.5 66 35 84.1 22.8 104.2 14.3 125.1 5.5 147.2 1 170 1m0-1c-23 0-45.2 4.5-66.2 13.4-20.2 8.6-38.4 20.8-54 36.4-15.6 15.6-27.9 33.8-36.4 54C4.5 124.8 0 147 0 170s4.5 45.2 13.4 66.2c8.6 20.2 20.8 38.4 36.4 54 15.6 15.6 33.8 27.9 54 36.4 21 8.9 43.2 13.4 66.2 13.4s45.2-4.5 66.2-13.4c20.2-8.6 38.4-20.8 54-36.4 15.6-15.6 27.9-33.8 36.4-54 8.9-21 13.4-43.2 13.4-66.2s-4.5-45.2-13.4-66.2c-8.6-20.2-20.8-38.4-36.4-54-15.6-15.6-33.8-27.9-54-36.4C215.2 4.5 193 0 170 0z"
		/>

		<path
			fill="none"
			stroke={primaryColor}
			strokeMiterlimit="10"
			strokeWidth="3"
			d="M13.2 170c0 21.2 4.1 41.7 12.3 61 7.9 18.7 19.2 35.4 33.6 49.8 14.4 14.4 31.2 25.7 49.8 33.6 19.3 8.2 39.9 12.3 61 12.3s41.7-4.1 61-12.3c18.7-7.9 35.4-19.2 49.8-33.6 14.4-14.4 25.7-31.2 33.6-49.8 8.2-19.3 12.3-39.9 12.3-61s-4.1-41.7-12.3-61c-7.9-18.7-19.2-35.4-33.6-49.8a156.93 156.93 0 00-49.8-33.6c-19.3-8.2-39.9-12.3-61-12.3s-41.7 4.1-61 12.3c-18.7 7.9-35.4 19.2-49.8 33.6S33.4 90.3 25.5 109c-8.2 19.3-12.3 39.8-12.3 61h0z"
		/>
	</svg>

export default RingAboutMenuBackground
