/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import videojs from 'video.js'

import styles from './Interview.module.scss'
import Page from './Page'
import Popup from './Popup'
import IconPlay from '../assets/IconPlay'
import { useEffectOnce } from 'react-use'

export { styles }

export const Video = ({ src, options, zoom = false }) => {
	const videoRef = useRef(null)
	const [player, setPlayer] = useState(null)
	const [playing, setPlaying] = useState(false)

	// videojs.options.children.loadingSpinner = false
	useEffectOnce(() => {
		const player = videojs(videoRef.current, {
			controls:      false,
			controlBar:    false,
			bigPlayButton: false,
			muted:         false,
			autoplay:      true,
			playsinline:   true,
			preload:       'auto',
			children:      ['mediaLoader'],
			...options,
		})

		setPlayer(player)

		player.addClass(styles.video)

		if (zoom)
			player.addClass(styles.zoom)

		player.on('play', () => setPlaying(true))
		player.on('pause', () => setPlaying(false))
	})

	const toggle = () => playing ? player.pause() : player.play()

	return <div className={styles.root}>
		<div className={styles.controls} onClick={toggle}>
			{!playing && <IconPlay />}
		</div>

		<video
			onClick={toggle}
			ref={videoRef}
		>
			<source src={src} type="application/x-mpegURL" />
		</video>
	</div>
}

export const Interview = ({ designer }) => {
	return <Page>
		<Popup
			toggle={<div
				style={{ backgroundImage: `url(${designer.designer_interview.poster})` }}
				className={styles.toggle}
			>
				<IconPlay />
			</div>}
			popup={<Video src={designer.designer_interview.video} />}
		/>
	</Page>
}
