/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react'
// import videojs from 'video.js'
// import { useEffectOnce } from 'react-use'

import styles from './Renderings.module.scss'
// import { Video, styles as interviewStyles } from './Interview'
// import Page from './Page'
// import Popup from './Popup'
// import IconPlay from '../assets/IconPlay'
import videojs from 'video.js'

export const Renderings = ({ designer }) => {
	// const videoRef = useRef(null)
	// const mobile = false // useWindowWidth() <= 800

	// useEffectOnce(() => {
	// 	if (videoRef.current)
	// 		videojs(videoRef.current, { children: ['mediaLoader'] }).addClass(styles.video)
	// })

	// const [ref, { x, y, width }] = useDimensions()

	const RenderingsVideo = ({ src, zoom = false }) => {
		const videoRef = useRef(null)

		useEffect(() => {
			const player = videojs(videoRef.current, {
				controls:      false,
				controlBar:    false,
				bigPlayButton: false,
				muted:         true,
				autoplay:      true,
				playsinline:   true,
				// preload:       'auto',
				loop:          true,
				children:      ['mediaLoader'],
			})

			player.addClass(styles.video)

			if (zoom) { player.addClass(styles.zoom) }

			// player.addClass(className)
			// player.src({ type: 'application/x-mpegURL', src: src })
			// player.play()

			return function() {
				if (player) {
					player.dispose()
				}
			}
		}, [src])

		return <div className={styles.videoContainer}>
			<video
				ref={videoRef}
			>
				<source src={src} type="application/x-mpegURL" />
			</video>
		</div>
	}

	return <div className={styles.root} style={{ backgroundImage: `url(${designer.designer_renderings.poster})` }}>
		{/*<Video
			src={designer.designer_renderings.video}
			options={{
				muted:    true,
				autoplay: true,
				loop:     true,
			}}
			zoom
		/>*/}
		<RenderingsVideo src={designer.designer_renderings.video} zoom />
	</div>

	// if (mobile) {
	// 	return <Page>
	// 		<Popup
	// 			toggle={<div
	// 				style={{ backgroundImage: `url(${designer.designer_renderings.poster})` }}
	// 				className={interviewStyles.toggle}
	// 			>
	// 				<IconPlay />
	// 			</div>}
	// 			popup={}
	// 		/>
	// 	</Page>
	// } else {
	// 	return <div className={styles.root}>
	// 		<video
	// 			ref={videoRef}
	// 			muted
	// 			autoPlay
	// 			loop
	// 			playsInline
	// 			title="Intro Mask Video"
	// 		>
	// 			<source src={designer.designer_renderings.video} type="application/x-mpegURL" />
	// 		</video>
	// 	</div>
	// }
}
