import * as React from 'react'

const IconWebsite = () => {
	return (
		<svg
			aria-labelledby="icon-website"
			height="48.5"
			role="img"
			viewBox="0 0 50.2 48.5"
			width="50.2"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-website">Icon Website</title>
			<path fill="#FFFFFF" d="M44.2 0H6C2.7 0 0 2.7 0 6.1v28c0 3.4 2.7 6.1 6 6.1h17.3V45H10.1c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h30c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8H26.9v-4.8h17.3c3.3 0 6-2.7 6-6.1v-28c0-3.4-2.7-6.1-6-6.1zM25.1 35.1c-.9 0-1.7-.7-1.7-1.7s.7-1.7 1.7-1.7c.9 0 1.7.7 1.7 1.7s-.8 1.7-1.7 1.7zm16.6-.8c-.5 0-.8-.4-.8-.8 0-.5.4-.8.8-.8s.8.4.8.8c.1.4-.3.8-.8.8zm5.9-6.7h-45V6.1c0-1.9 1.5-3.5 3.4-3.5h38.2c1.9 0 3.4 1.6 3.4 3.5v21.5z" />
		</svg>

	)
}

export default IconWebsite
