/* eslint-disable react/prop-types */
import * as React from 'react'

import RingProfileBackground from './RingProfileBackground.js'
import styles from './RingProfile.module.scss'

const RingProfile = ({ style, children, className, primaryColor, gradientStart, gradientMiddle, gradientEnd }) =>
	<div className={styles.root + ' ' + className} style={style}>
		<div className={styles.content}>
			{children}
		</div>

		<RingProfileBackground
			className={styles.bg}
			primaryColor={primaryColor}
			gradientStart={gradientStart}
			gradientMiddle={gradientMiddle}
			gradientEnd={gradientEnd}
		/>
	</div>

export default RingProfile
