import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './react/app'
import PopupRoot from './react/DesignerPage/PopupRoot'

document.addEventListener('DOMContentLoaded', () => {
	ReactDOM.render(
		<BrowserRouter>
			<PopupRoot>
				<App />
			</PopupRoot>
		</BrowserRouter>,
		document.body.appendChild(document.createElement('div')),
	)
})
