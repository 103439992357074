/* eslint-disable react/prop-types */
import * as React from 'react'
import styles from './Work.module.scss'
import IconInstagram from '../assets/IconInstagram'
import c from 'classnames'
import { styles as interviewStyles } from './Interview'
import Page from './Page'
import Popup from './Popup'
import IconPlay from '../assets/IconPlay'
import WorkSlide from './WorkSlide'

// eslint-disable-next-line react/display-name
export const Work = index => ({ designer }) => {
	const designerWork = designer.designer_work[0]
	const works = designerWork.images
	const work = works[index]
	const mobile = false // useWindowWidth() <= 800

	if (mobile) {
		return <Page>
			<Popup
				toggle={<div
					style={{ backgroundImage: `url(${works[0]})` }}
					className={interviewStyles.toggle}
				>
					<IconPlay />
				</div>}
				popup={<WorkSlide designer={designer} />}
			/>
		</Page>
	} else {
		return <Page className={styles.noPointer}>
			<div className={c(styles.root, designer.id)} style={{ '--bgi': `url(${work})` }} />
		</Page>
	}
}
