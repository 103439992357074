/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'

import c from 'classnames'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { useMeasure } from 'react-use'
import clamp from 'lodash-es/clamp'

import styles from './ProfileGestureRoot.module.scss'

import IconChevronLeft from './assets/IconChevronLeft'
import IconChevronRight from './assets/IconChevronRight'

import { Renderings, Work, Interview } from './DesignerPage'

import DesignerInfo from './DesignerInfo'
import DesignerInfoSwatch from './DesignerInfoSwatch'
import DesignerInfoNav from './DesignerInfoNav'
import { useHistory } from 'react-router'
import { useWindowWidth } from '@react-hook/window-size'
import IconInstagram from './assets/IconInstagram'
import { setProfileSwipe } from './events'

const DesignerPhoto = ({ designer }) =>
	<div
		className={styles.photo}
		style={{ backgroundImage: `url(${designer.profile})` }}
	/>

const listFactory = (designer/*, mobile*/) => {
	const list = [
		{
			Component: DesignerPhoto,
			page:      `/profiles/${designer.id}`,
		},
		{
			Component: DesignerInfo,
			set:       'info',
			page:      `/profiles/${designer.id}/info`,
		},
		{
			Component: DesignerInfoSwatch,
			set:       'info',
			page:      `/profiles/${designer.id}/info`,
		},
		{
			Component: Interview,
			page:      `/profiles/${designer.id}/interview`,
		},
		...(
			designer.designer_work[0].images/*.filter((v, i) => !mobile || i < 1)*/.map((v, i) => {
				const Component = Work(i)
				Component.displayName = `WorkIndex${i}`

				return {
					Component,
					set:  'work',
					page: `/profiles/${designer.id}/work`,
				}
			})
		),
		{
			Component: Renderings,
			page:      `/profiles/${designer.id}/renderings`,
		},
	]

	const sets = list.reduce((acc, curr, currI) => {
		const { set } = curr

		if (set) {
			acc[set] || (acc[set] = [])
			acc[set].push(currI)
		}

		return acc
	}, {})

	const MAX_INDEX = list.length - 1

	const back = (set, current) => () =>
		(current === 0)
			? set(MAX_INDEX)
			: set(current - 1)

	const next = (set, current) => () =>
		(current === MAX_INDEX)
			? set(0)
			: set(current + 1)


	return { list, sets, MAX_INDEX, next, back }
}

// const endSwipe = /*debounce(*/({ MAX_INDEX, down, mx, xDir, index, prevIndex, xSwipe, setStateIndex, setMx, cancel }) => {

// }//, 300)

const ProfileGestureRoot = ({ designer, navigating = false }) => {
	const mobile = useWindowWidth() <= 800
	const { list, sets, MAX_INDEX, next, back } = listFactory(designer, mobile)
	const index = useRef(0)
	const cycle = useRef(true)
	const [stateIndex, setStateIndex] = useState(index.current)
	const [mx, setMx] = useState(0)
	const [ref, { width }] = useMeasure()
	const props = useSpring({ x: (stateIndex * -width) + mx })
	const large = true
	const currentSet = sets[list[stateIndex].set]
	const currentPage = list[stateIndex]
	const history = useHistory()
	const historyCooldown = useRef(false)

	function cooldown() {
		historyCooldown.current = true
		setTimeout(() => (historyCooldown.current = false), 100)
	}

	function setIndex(val) {
		index.current = val
		setStateIndex(val)
	}

	useEffect(() => {
		if (historyCooldown.current) return

		const i = list.findIndex(item => item.page === history.location.pathname)

		if (i !== -1) {
			cooldown()
			setIndex(i)
		}

	}, [history.location.pathname])

	useEffect(() => {
		if (historyCooldown.current) return

		if (currentPage.page) {
			cooldown()
			history.push(currentPage.page)
		}
	}, [currentPage.page])

	// Set the drag hook and define component movement based on gesture data
	const bind = useDrag(({ down, movement: [mx], /*direction: [xDir],*/ swipe: [xSwipe, ySwipe], /*distance,*/ cancel }) => {
		setMx(down ? mx : 0)

		if (xSwipe !== 0) {
			// so far in my tests, this function gets invoked exactly 2 times when it is called,
			// so i alternate which time it is called
			if (cycle.current) {
				cycle.current = false

				index.current = clamp(Math.round(stateIndex - xSwipe), 0, MAX_INDEX)
				// prevIndex.current = index.current
				setStateIndex(index.current)
				setMx(0)
				cancel()
			} else {
				setMx(0)
				cycle.current = true
			}

			return
		}
	})

	const dragHandlers = (mobile ? bind() : [])

	return <>
		<div
			className={c(styles.profileName, designer.id)}
			style={{ opacity: stateIndex === 0 && !navigating ? 1 : 0 }}
		>
            Meet<br /><strong>{designer.name}</strong>
		</div>

		<div
			ref={ref}
			className={styles.root}
			style={{ opacity: navigating ? 0 : 1 }}
		>
			<div className={c(styles.arrows, designer.id, {
				[styles.largeArrows]:      large,
				[styles.renderingsArrows]: currentPage.page.endsWith('renderings'),
			})}>
				{index.current != 0
				&& <IconChevronLeft className={styles.left} onClick={back(setIndex, stateIndex)} />}
				{/*index.current != MAX_INDEX
				&& */<IconChevronRight className={styles.right} onClick={next(setIndex, stateIndex)} />}
			</div>

			<animated.div
				className={styles.slide}
				{...dragHandlers}
				style={props}
			>
				{list.map(({ Component }) =>
					<Component
						key={(Component.name || Component.displayName) + '-' + designer.id}
						designer={designer}
					/>,
				)}
			</animated.div>

			{currentSet && currentSet.length > 1 && <DesignerInfoNav
				list={currentSet}
				set={setIndex}
				current={stateIndex}
			/>}

			{currentPage.set === 'work' && <a className={styles.wornBy} href={designer.designer_work[0].model.instagram} target="_blank" rel="noreferrer">
				<IconInstagram className={styles.instagram} />

				<div>
					Worn by
					<div dangerouslySetInnerHTML={{ __html: designer.designer_work[0].model.name }} />
				</div>
			</a>}
		</div>
	</>
}

export default ProfileGestureRoot
