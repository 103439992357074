/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react'
import { useInterval } from 'react-use'
import videojs from 'video.js'

import RingAboutMenuBackground from './assets/RingAboutMenuBackground'
import IconInstagram from './assets/IconInstagram'
import IconWebsite from './assets/IconWebsite'
import IconSwatchEdge from './assets/IconSwatchEdge'
import styles from './AboutPageTemplate.module.scss'
import { setPageHeight } from './events'
// import Config from './assets.json'

const sectionHeight = 1000

const ImageSequence = ({ images, applyMask, sequenceBackground = undefined, sequenceLogo = undefined }) => {
	const el = useRef(null)
	const [i, setIndex] = useState(0)

	useInterval(() =>
		setIndex(i < images.length - 1
			? i + 1
			: 0), 2500)

	return <div className={styles.imageSequenceContainer}>
		{!!sequenceBackground && (
			<div className={styles.imageSequenceBackground} style={{ backgroundImage: `url(${sequenceBackground})` }} />
		)}

		{!!sequenceLogo && (
			<div className={styles.imageSequenceLogo} style={{ backgroundImage: `url(${sequenceLogo})` }} />
		)}

		<div
			ref={el}
			className={`${applyMask ? styles.masked : ''} ${styles.imageSequence}`}
			style={{ backgroundImage: `url(${images[i]})` }}
		/>
	</div>
}

const Video = ({ refId, className, src, type, poster, applyMask, containerClass }) => {
	// Types:
	//   • Streaming / .m3u8 files `application/x-mpegURL`
	//   • .mp4 files `video/mp4`

	const videoRef = useRef(refId)

	useEffect(() => {
		const player = videojs(videoRef.current, {
			controls:      false,
			controlBar:    false,
			bigPlayButton: false,
			muted:         true,
			autoplay:      true,
			playsinline:   true,
			preload:       'auto',
			loop:          true,
			children:      ['mediaLoader'],
		})

		player.addClass(className)
		player.src({ type: type, src: src })

		return function() {
			if (player) {
				player.dispose()
			}
		}
	}, [src])

	return <div className={containerClass}>
		<div data-vjs-player className={`${applyMask ? styles.masked : ''}`}>
			<video
				ref={videoRef}
				poster={poster}
			>
				<source src={src} type={type} />
			</video>
		</div>
	</div>
}

const AboutPageTemplate = ({ id, y, sections, backgroundPoster, backgroundVideo, menu }) => {
	const pageHeight = useRef(0)

	useEffect(() => {
		setPageHeight(pageHeight.current)
		return () => setPageHeight(0)
	}, [pageHeight.current])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return <div className={styles.root}>
		{sections.map((section, si) => {
			const showAt = sectionHeight * si
			const hideAt = showAt + sectionHeight
			let opacity = 0
			let x = -100
			let zIndex = 2

			if (y >= showAt) {
				opacity = 1
				zIndex = 3
				x = 0
			}

			if (y > hideAt - 1) {
				opacity = 0
				x = 100
			}

			return <div
				className={styles.section}
				key={si}
				style={{
					opacity,
					transform: `translateX(${x}px)`,
					zIndex,
				}}
			>

				{section.video &&
					<Video refId={section.video_ref_id} poster={section.video_poster} src={section.video} type='application/x-mpegURL' className={styles.video} applyMask={section.apply_mask} containerClass={styles.videoSequenceContainer} />}

				{section.image_sequence &&
					<div className={styles.sequenceCacher}>
						{section.image_sequence.map((value, index) => {
							return <div key={index} style={{ backgroundImage: `url(${value})` }} />
						})}
					</div>}

				{section.image_sequence &&
					<ImageSequence images={section.image_sequence} applyMask={section.apply_mask} sequenceBackground={section.image_sequence_background} sequenceLogo={section.image_sequence_logo} />}

				<div className={styles.contentContainer}>
					<div
						className={styles.headline}
						dangerouslySetInnerHTML={{ __html: section.headline }}
					/>

					<div className={styles.contentList}>
						{section.content.map((content, ci) => {
							const contentHeight = sectionHeight / section.content.length
							const contentShowAt = showAt + contentHeight * ci
							const hideAt = contentShowAt + contentHeight
							let opacity = 0
							let x = -100
							let zIndex = 0

							if (y >= contentShowAt) {
								opacity = 1
								zIndex = 1
								x = 0
							}

							if (y > hideAt - 1) {
								opacity = 0
								x = 100
							}

							if (pageHeight.current < hideAt)
								pageHeight.current = hideAt

							return <div
								key={ci}
								className={styles.content}
								dangerouslySetInnerHTML={{ __html: content }}
								style={{
									opacity,
									transform: `translateX(${x}px)`,
									zIndex,
								}}
							/>
						})}
					</div>
				</div>
			</div>
		})}

		<div className={styles.menu}>
			<div className={styles.menuItems}>
				<a href={menu.website} target="_blank" rel="noreferrer">
					<div><IconWebsite /></div>
					Join Swatchon
				</a>

				<a href={menu.instagram} target="_blank" rel="noreferrer">
					<div><IconInstagram /></div>
					Follow Us!
				</a>
			</div>

			<RingAboutMenuBackground
				primaryColor={menu.colors.primary_color}
				gradientStart={menu.colors.gradient_start}
				gradientMiddle={menu.colors.gradient_middle}
				gradientEnd={menu.colors.gradient_end}
			/>
		</div>

		<div className={styles.floaters}>
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
			<IconSwatchEdge />
		</div>

		<Video
			refId={`${id}-background-video-player`}
			className={styles.backgroundVideo}
			poster={backgroundPoster}
			src={backgroundVideo}
			containerClass={styles.videoBackgroundContainer}
			type='application/x-mpegURL'
		/>
	</div>
}

export default AboutPageTemplate
