/* eslint-disable react/prop-types */
import * as React from 'react'
import c from 'classnames'

import styles from './DesignerInfoSwatch.module.scss'
import IconSwatch from './assets/IconSwatch'

const DesignerInfoSwatch = ({ designer }) => {
	const info = designer.designer_info[1]

	return <div className={styles.root}>
		<a className={styles.swatchLink} href={info.swatchon} target="_blank" rel="noreferrer">
			<IconSwatch />
			<small>View on SwatchOn</small>
		</a>

		<div className={styles.inner}>
			<div
				className={c(
					styles.content,
					styles[designer.id],
					designer.id,
					styles['page-1'],
				)}
			>
				<div className={styles.headline} dangerouslySetInnerHTML={{ __html: info.headline }} />
				<div className={styles.copy} dangerouslySetInnerHTML={{ __html: info.copy }} />
			</div>
		</div>
	</div>
}

export default DesignerInfoSwatch
