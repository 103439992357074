/* eslint-disable react/prop-types */
import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { useEffectOnce } from 'react-use'

const RingProfileBackground = ({
	className,
	opacityCircleOuter = '0.25',
	opacityCircleInner = '0.75',
	primaryColor = 'black',
	gradientStart = 'black',
	gradientMiddle = '#929497',
	gradientEnd = 'black',
}) => {
	let [id, setId] = React.useState('-')
	useEffectOnce(() => setId(uuid()))

	return <svg
		aria-labelledby="ring-image-background"
		className={className}
		height="1283"
		role="img"
		viewBox="0 0 1283 1283"
		width="1283"
		xmlns="http://www.w3.org/2000/svg">

		<path
			id="circle-outer-background-translucent-white"
			fill="#fff"
			opacity={opacityCircleOuter}
			d="M1252.2 641.8c0 337.6-273.7 611.3-611.3 611.3S29.7 979.4 29.7 641.8 303.4 30.5 641 30.5s611.2 273.7 611.2 611.3" />

		<path
			id="circle-inner-background-translucent-colored"
			fill={primaryColor}
			opacity={opacityCircleInner}
			d="M448.2 184c-59.1 25-112.2 60.8-157.8 106.4C244.8 336 209 389.1 184 448.2c-25.9 61.2-39 126.2-39 193.3 0 67 13.1 132.1 39 193.3 25 59.1 60.8 112.2 106.4 157.8 45.6 45.6 98.7 81.4 157.8 106.4 61.2 25.9 126.2 39 193.3 39 67 0 132.1-13.1 193.3-39 59.1-25 112.2-60.8 157.8-106.4 45.6-45.6 81.4-98.7 106.4-157.8 25.9-61.2 39-126.2 39-193.3 0-67-13.1-132.1-39-193.3-25-59.1-60.8-112.2-106.4-157.8C947 244.8 893.9 209 834.8 184c-61.2-25.9-126.2-39-193.3-39-67 0-132.1 13.1-193.3 39" />

		<linearGradient id={id} x1="-319.343" x2="-318.343" y1="1181.453" y2="1181.453" gradientTransform="scale(-1049.349 1049.349) rotate(55 974.46 896.69)" gradientUnits="userSpaceOnUse">
			<stop offset="0" stopColor={gradientStart} />
			<stop offset=".496" stopColor={gradientMiddle} />
			<stop offset="1" stopColor={gradientEnd} />
		</linearGradient>

		<path
			id="ring-thick-gradient-colored"
			fill={`url(#${id})`}
			d="M932.6 225.8l-9.8 13.9c-85.7-60-183.7-88.7-280.9-88.7-154.7 0-306.8 72.9-402.2 209.2-60 85.7-88.7 183.7-88.7 280.9 0 154.7 72.9 306.8 209.2 402.2 85.7 60 183.7 88.7 280.9 88.7 154.7 0 306.8-72.9 402.2-209.2 60-85.7 88.7-183.7 88.7-280.9 0-154.7-72.9-306.8-209.2-402.2l9.8-13.9 9.8-13.9c145.6 102 223.6 264.8 223.6 430 0 103.9-30.8 208.9-94.9 300.4-102 145.7-264.8 223.7-430 223.7-103.9 0-208.9-30.8-300.4-94.9C195 969.1 117 806.3 117 641.1c0-103.9 30.8-208.9 94.9-300.4C313.9 195 476.7 117 641.9 117c103.9 0 208.9 30.8 300.4 94.9l-9.7 13.9z" />

		<path
			id="ring-medium-solid-colored"
			fill="none"
			stroke={primaryColor}
			strokeMiterlimit="10"
			strokeWidth="9.985"
			d="M63.7 641.5c0 78 15.3 153.7 45.4 224.9 29.1 68.8 70.8 130.6 123.8 183.7 53.1 53.1 114.9 94.7 183.7 123.8 71.2 30.1 146.9 45.4 224.9 45.4s153.7-15.3 224.9-45.4c68.8-29.1 130.6-70.8 183.7-123.8 53.1-53.1 94.7-114.9 123.8-183.7 30.1-71.2 45.4-146.9 45.4-224.9s-15.3-153.7-45.4-224.9c-29.1-68.8-70.8-130.6-123.8-183.7-53.1-53.1-114.9-94.7-183.7-123.8C795.2 79 719.5 63.7 641.5 63.7S487.8 79 416.6 109.1c-68.8 29.1-130.6 70.8-183.7 123.8-53.1 53.1-94.7 114.9-123.8 183.7-30.2 71.2-45.4 146.9-45.4 224.9z" />

		<path
			id="ring-thin-solid-white"
			fill="#fff"
			d="M641.5 5c85.9 0 169.2 16.8 247.7 50 75.9 32.1 144 78 202.4 136.4C1150.1 250 1196 318.1 1228 393.8c33.2 78.5 50 161.8 50 247.7s-16.8 169.2-50 247.7c-32.1 75.9-78 144-136.4 202.4-58.6 58.5-126.7 104.4-202.4 136.4-78.5 33.2-161.8 50-247.7 50s-169.2-16.8-247.7-50c-75.9-32.1-144-78-202.4-136.4C132.9 1033 87 964.9 55 889.2 21.9 810.7 5 727.4 5 641.5 5 555.3 21.9 472 55 393.8c32.1-75.9 78-144 136.4-202.4C250 132.9 318.1 87 393.8 55 472.3 21.9 555.6 5 641.5 5m0-5c-86.6 0-170.6 17-249.7 50.4-76.4 32.3-145 78.6-204 137.4-59 59-105.1 127.6-137.4 204C16.9 470.9 0 554.9 0 641.5s17 170.6 50.4 249.7c32.3 76.4 78.6 145 137.4 204 59 59 127.6 105.1 204 137.4 79 33.4 163.1 50.4 249.7 50.4s170.6-17 249.7-50.4c76.4-32.3 145-78.6 204-137.4 59-59 105.1-127.6 137.4-204 33.4-79 50.4-163.1 50.4-249.7s-17-170.6-50.4-249.7c-32.3-76.4-78.6-145-137.4-204-59-59-127.6-105.1-204-137.4C812.1 17 728.1 0 641.5 0z" />
	</svg>
}

export default RingProfileBackground
