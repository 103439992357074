/* eslint-disable react/prop-types */
import React, { useContext } from 'react'

import styles from './Popup.module.scss'
import { PopupContext } from './PopupRoot'

const Popup = ({ toggle: toggleEl, popup }) => {
	const { showContent } = useContext(PopupContext)

	return <div
		className={styles.root}
		onClick={() => showContent(popup)}
	>
		{toggleEl}
	</div>
}

export default Popup
