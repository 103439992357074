/* eslint-disable react/prop-types */
import * as React from 'react'

const IconSwatch = ({ className }) =>
	<svg
		className={className}
		aria-labelledby="icon-swatch"
		height="63.2"
		role="img"
		viewBox="0 0 50.7 63.2"
		width="50.7"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
	>
		<title id="icon-swatch">Icon Swatch</title>
		<path fill="transparent" stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth="1.5" d="M50.4 56.6l-6.3-6.3 6.3-6.2-6.3-6.3 6.3-6.2-6.3-6.3 6.3-6.2-6.3-6.2 6.3-6.3L44.1.4l-6.3 6.2L31.7.4l-6.3 6.2L19.2.4l-6.3 6.2L6.7.4.4 6.6 6.6 13 .4 19.1l6.2 6.3-6.2 6.2 6.2 6.3-6.2 6.2 6.2 6.3-6.2 6.2 6.2 6.3 6.3-6.3 6.2 6.3 6.3-6.3 6.2 6.3 6.3-6.3 6.1 6.3 6.4-6.3" />
	</svg>


export default IconSwatch
