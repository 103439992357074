/* eslint-disable react/prop-types */
import * as React from 'react'
import c from 'classnames'

import styles from './DesignerInfoNav.module.scss'

const DesignerInfoNav = ({ list, set, current }) =>
	<>
		<div className={styles.root}>
			{list.map(item =>
				<div
					className={c(styles.button, { [styles.active]: current === item })}
					key={item}
					onClick={() => set(item)}
				/>,
			)}
		</div>
	</>

export default DesignerInfoNav
