/* eslint-disable react/prop-types */
import * as React from 'react'

const IconChevronLeft = ({ onClick }) => {
	return (
		<svg
			onClick={onClick}
			aria-labelledby="icon-chevron-left"
			height="42.1"
			role="img"
			viewBox="0 0 22.8 42.1"
			width="22.8"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-chevron-left">Icon Chevron Left</title>
			<path fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.4" d="M21.6 40.9L1.7 21.1 21.6 1.2" />
		</svg>
	)
}

export default IconChevronLeft
