import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'

import IconMenu from './assets/IconMenu'
import IconMenuClose from './assets/IconMenuClose'
import MenuHomeButton from './assets/MenuButtonHome'

import styles from './Menu.module.scss'

const Menu = () => {
	const [open, toggle] = useToggle(false)
	const [canBlur, setCanBlur] = useState(true)
	const hide = () => toggle(false)
	const show = () => toggle(true)

	return <div
		className={styles.root}
		tabIndex={0}
		onBlur={() => canBlur && hide()}
		onMouseUp={() => setCanBlur(true)}
		onMouseDown={() => setCanBlur(false)}
	>
		<div className={styles.toggle} onClick={toggle}>{open ? <IconMenuClose /> : <IconMenu />}</div>
		<div id={styles.menuContainer} className={`${open ? styles.open : ''}`}>
			<div className={styles.bg} />

			<div className={styles.menu}>
				<Link to="/" onClick={toggle} onBlur={hide} onFocus={show}>
					<MenuHomeButton />
				</Link>

				<Link to="/profiles" onClick={toggle} onBlur={hide} onFocus={show}>
					<small>Meet</small>
					The Designers
				</Link>

				<Link to="/about/contest" onClick={toggle} onBlur={hide} onFocus={show}>
					<small>About</small>
					The Contest
				</Link>

				<Link to="/about/swatchon" onClick={toggle} onBlur={hide} onFocus={show}>
					<small>About</small>
					Swatchon
				</Link>
			</div>
		</div>
	</div>
}

export default Menu
