/* eslint-disable react/prop-types */
import * as React from 'react'

const IconScroll = () => {
	return (
		<svg
			aria-labelledby="icon-scroll"
			height="81.8"
			role="img"
			viewBox="0 0 72 81.8"
			width="72"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-scroll">Icon Scroll</title>
			<g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2">
				{/*<circle cx="34.9" cy="48.1" r="31"/>*/}
				<path d="M19.2 36.4L35 50.7l15.6-14.3" strokeWidth="4"/>
				<path d="M19.2 48.4L35 62.8l15.6-14.4" strokeWidth="4"/>
			</g>
			<g fill="#FFF">
				<path d="M11.1 11.3c-.5-.1-1.1-.3-1.7-.6l.6-1.3c.5.3 1 .4 1.5.6.5.1.9.2 1.5.2.5 0 .9-.1 1.2-.3.3-.2.4-.5.4-.9 0-.2-.1-.5-.2-.6-.2-.2-.4-.3-.7-.4-.3-.1-.6-.2-1.1-.3-.7-.1-1.2-.3-1.7-.5-.4-.2-.8-.4-1-.8-.3-.3-.4-.7-.4-1.3 0-.8.3-1.4.8-1.8.5-.5 1.3-.7 2.3-.7 1.2 0 2.3.2 3.2.7l-.6 1.2c-.9-.4-1.7-.5-2.6-.5-.5 0-.9.1-1.2.3s-.4.5-.4.8c0 .2.1.5.2.6.2.2.4.3.7.4.3.1.7.2 1.2.3.6.1 1.2.3 1.6.5.4.2.7.4 1 .8.2.3.4.7.4 1.3 0 .9-.3 1.5-.8 1.9-.5.4-1.3.6-2.3.6-.8-.1-1.4-.1-1.9-.2zM19.5 10.9c-.7-.4-1.2-.9-1.5-1.5-.4-.7-.5-1.5-.5-2.3 0-.9.2-1.7.5-2.3.4-.7.9-1.2 1.5-1.5.7-.3 1.4-.5 2.3-.5.8 0 1.5.1 2.1.4.6.3 1.1.7 1.6 1.2l-1.2.9c-.3-.4-.6-.7-1-.9-.4-.3-.9-.4-1.4-.4-.6 0-1.1.1-1.5.4-.4.2-.8.6-1 1-.3.5-.4 1-.4 1.6 0 .6.1 1.2.4 1.6.2.5.6.8 1 1.1s.9.4 1.5.4 1-.1 1.5-.3c.4-.2.8-.5 1-.9l1.2.9c-.9 1.1-2.1 1.6-3.7 1.6-.9 0-1.7-.1-2.4-.5zM32.5 11.3l-1.8-2.5h-2v2.5h-1.5V2.8h3.4c1.1 0 1.9.3 2.5.8s.9 1.2.9 2.2c0 .7-.1 1.2-.4 1.7s-.7.8-1.3 1l2 2.8h-1.8zm-.6-4.2c.4-.3.5-.7.5-1.2 0-1.2-.6-1.7-1.9-1.7h-1.8v3.4h1.6c.7-.1 1.2-.3 1.6-.5zM37.7 10.9c-.7-.4-1.2-.9-1.6-1.5-.4-.7-.6-1.4-.6-2.3 0-.9.2-1.6.6-2.3.4-.7.9-1.2 1.6-1.6.7-.4 1.5-.6 2.4-.6.9 0 1.7.2 2.3.5.7.4 1.2.9 1.6 1.5.4.7.6 1.4.6 2.3 0 .8-.2 1.6-.6 2.3s-.9 1.2-1.6 1.6c-.7.4-1.5.6-2.4.6-.8 0-1.6-.1-2.3-.5zm3.9-1.2c.5-.2.8-.6 1.1-1.1.3-.5.4-1 .4-1.6 0-.6-.1-1.1-.4-1.6s-.6-.8-1.1-1.1c-.4-.2-1-.3-1.6-.3-.6 0-1.1.1-1.5.4s-.8.6-1.1 1c-.3.5-.4 1-.4 1.6 0 .6.1 1.1.4 1.6s.6.8 1.1 1.1 1 .4 1.6.4c.5 0 1-.1 1.5-.4zM46.4 2.8h1.5v7.1h4.6v1.3h-6.1V2.8zM54.1 2.8h1.5v7.1h4.6v1.3h-6.1V2.8z"/>
			</g>
		</svg>
	)
}

export default IconScroll
