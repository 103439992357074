import * as React from 'react'

const IconSwatchEdge = () =>
	<svg
		aria-labelledby="icon-swatch-edge"
		height="1181.1"
		role="img"
		viewBox="0 0 37.9 1181.1"
		width="37.9"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
	>
		<title id="icon-swatch-edge">Icon Swatch Edge</title>
		<path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth=".612" d="M.4.2l37 36.9L.4 74l37 36.8-37 37 37 36.8-37 37 37 36.8-37 37 37 36.8-37 37 37 36.8-37 37 37 36.8-37 36.9 37 36.9-37 36.9 37 36.9-37 36.9 37 36.9-37 36.9 37 36.9-37 36.9 37 36.8-37 37 37 36.8-37 37 37 36.8-37 37 37 36.8-37 37 37 36.8-37 37"/>
	</svg>

export default IconSwatchEdge
