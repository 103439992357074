/* eslint-disable react/prop-types */
import React from 'react'

import styles from './ScrollIndicator.module.scss'
import IconScroll from './assets/IconScroll'

const setScrollIndicator = (y, show, hide) => {
	let opacity = 0

	if (y > hide) {
		opacity = 0
	} else if (y >= show) {
		opacity = 1
	}

	return { opacity }
}

const ScrollIndicator = ({ y, show, hide }) => {
	return <div className={styles.iconScroll} style={setScrollIndicator(y, show, hide)}>
		<IconScroll />
	</div>
}

export default ScrollIndicator