/* eslint-disable react/prop-types */
import * as React from 'react'

const IconMenuClose = ({ className, onClick }) =>
	<svg
		className={className}
		onClick={onClick}
		aria-labelledby="icon-menu-close"
		height="49"
		role="img"
		viewBox="0 0 49 49"
		width="49"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
	>
		<title id="icon-menu-close">Icon Menu Close</title>

		<line x1="2.5" y1="46.5" x2="46.5" y2="2.5" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
		<line x1="46.5" y1="46.5" x2="2.5" y2="2.5" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
	</svg>

export default IconMenuClose


