/* eslint-disable react/prop-types */
import * as React from 'react'

const IconPlay = ({ onClick }) =>
	<svg
		onClick={onClick}
		aria-labelledby="icon-play"
		height="63"
		role="img"
		viewBox="0 0 63 63"
		width="63"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
	>
		<title id="icon-play">Icon Play</title>
		<g opacity=".8">
			<circle fill="#FFFFFF" cx="31.5" cy="31.5" r="31.5" />
			<path fill="#242020" d="M43.3 28.8c-6-3-11.9-5.9-17.9-9-1.9-.9-2.6-.4-2.6 1.6v20c0 2 .7 2.7 2.6 1.6 6-3.7 12-7.5 18-11.2 1.7-1 1.9-2-.1-3zm-18 11.4V22.6c5.3 2.6 10.2 5.1 15.7 7.8-5.4 3.4-10.3 6.4-15.7 9.8z" />
		</g>
	</svg>

export default IconPlay
