/* eslint-disable react/prop-types */
import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { useEffectOnce } from 'react-use'

const RingTextBackground = ({
	className,
	opacity = '0.75',
	primaryColor = 'black',
	gradientStart = 'black',
	gradientMiddle = '#929497',
	gradientEnd = 'black',
}) => {
	let [id, setId] = React.useState('-')
	useEffectOnce(() => setId(uuid()))

	return <svg
		aria-labelledby="ring-text-background"
		className={className}
		height="1166.026"
		role="img"
		viewBox="0 0 1166.026 1166.026"
		width="1166.026"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title id="ring-text-background">Ring Text Background</title>
		<linearGradient id={id} x1="-40.929" x2="1009.318" y1="466.464" y2="466.464" gradientTransform="rotate(-99.997 582.525 483.283)" gradientUnits="userSpaceOnUse">
			<stop offset="0" stopColor={gradientStart} />
			<stop offset=".496" stopColor={gradientMiddle} />
			<stop offset="1" stopColor={gradientEnd} />
		</linearGradient>

		<circle cx="583.013" cy="583.013" r="507.624" fill="none" stroke={`url(#${id})`} strokeMiterlimit="10" strokeWidth="35" />
		<path fill="none" stroke="#929497" strokeMiterlimit="10" strokeWidth="10" d="M5 583.013c0 78.04 15.28 153.736 45.415 224.982 29.111 68.827 70.791 130.644 123.882 183.734s114.908 94.771 183.735 123.882c71.247 30.135 146.942 45.415 224.982 45.415s153.735-15.28 224.983-45.415c68.826-29.111 130.643-70.791 183.733-123.882 53.091-53.091 94.77-114.908 123.882-183.734 30.135-71.247 45.415-146.942 45.415-224.982s-15.28-153.736-45.415-224.982c-29.112-68.827-70.791-130.644-123.882-183.735-53.09-53.091-114.907-94.771-183.733-123.882C736.748 20.28 661.053 5 583.013 5S429.277 20.28 358.031 50.415c-68.827 29.111-130.644 70.791-183.735 123.882S79.526 289.204 50.415 358.031C20.28 429.277 5 504.973 5 583.013z"/>

		<path
			d="M105.533 583.28c0 64.398 12.608 126.861 37.476 185.653 24.022 56.795 58.416 107.806 102.225 151.615s94.82 78.203 151.615 102.225c58.792 24.867 121.255 37.476 185.653 37.476s126.86-12.608 185.653-37.476c56.795-24.022 107.805-58.416 151.615-102.225 43.81-43.81 78.203-94.82 102.225-151.615 24.867-58.792 37.476-121.255 37.476-185.653s-12.609-126.861-37.476-185.653c-24.022-56.795-58.415-107.806-102.225-151.615-43.809-43.81-94.82-78.203-151.615-102.225-58.793-24.867-121.255-37.476-185.653-37.476s-126.861 12.608-185.653 37.476c-56.795 24.022-107.806 58.416-151.615 102.225s-78.203 94.82-102.225 151.615c-24.868 58.792-37.476 121.255-37.476 185.653z"
			opacity={opacity}
			fill={primaryColor}
		/>
	</svg>
}

export default RingTextBackground
