/* eslint-disable react/prop-types */
import * as React from 'react'

const IconInstagram = ({ className }) => {
	return (
		<svg
			className={className}
			aria-labelledby="icon-instagram"
			height="48.6"
			role="img"
			viewBox="0 0 48.6 48.6"
			width="48.6"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-instagram">Icon Instagram</title>
			<g>
				<path fill="#FFFFFF" d="M24.3 4.4c6.5 0 7.3 0 9.8.1 2.4.1 3.7.5 4.5.8 1.1.4 1.9 1 2.8 1.8.8.8 1.4 1.7 1.8 2.8.3.9.7 2.1.8 4.5.1 2.6.1 3.3.1 9.8 0 6.5 0 7.3-.1 9.8-.1 2.4-.5 3.7-.8 4.5-.4 1.1-1 1.9-1.8 2.8-.8.8-1.7 1.4-2.8 1.8-.9.3-2.1.7-4.5.8-2.6.1-3.3.1-9.8.1-6.5 0-7.3 0-9.8-.1-2.4-.1-3.7-.5-4.5-.8-1.1-.4-1.9-1-2.8-1.8-.8-.8-1.4-1.7-1.8-2.8-.3-.9-.7-2.1-.8-4.5-.1-2.6-.1-3.3-.1-9.8 0-6.5 0-7.3.1-9.8 0-2.3.4-3.6.8-4.4.4-1.1 1-1.9 1.8-2.8.8-.9 1.6-1.4 2.8-1.8.9-.3 2.1-.7 4.5-.8 2.5-.2 3.3-.2 9.8-.2m0-4.4c-6.6 0-7.4 0-10 .1-2.6.1-4.3.5-5.9 1.1-1.6.6-3 1.5-4.3 2.8-1.4 1.4-2.2 2.8-2.8 4.4-.6 1.5-1 3.3-1.1 5.9-.2 2.6-.2 3.4-.2 10s0 7.4.1 10c.1 2.6.5 4.3 1.1 5.9.6 1.6 1.5 3 2.8 4.3 1.3 1.3 2.7 2.2 4.3 2.8 1.5.6 3.3 1 5.9 1.1 2.6.1 3.4.1 10 .1s7.4 0 10-.1c2.6-.1 4.3-.5 5.9-1.1 1.6-.6 3-1.5 4.3-2.8 1.3-1.3 2.2-2.7 2.8-4.3.6-1.5 1-3.3 1.1-5.9.1-2.6.1-3.4.1-10s0-7.4-.1-10c-.1-2.6-.5-4.3-1.1-5.9-.6-1.6-1.5-3-2.8-4.3-1.3-1.4-2.7-2.2-4.3-2.8-1.5-.6-3.3-1-5.9-1.1-2.5-.2-3.3-.2-9.9-.2" />
				<path fill="#FFFFFF" d="M24.3 11.8c-6.9 0-12.5 5.6-12.5 12.5s5.6 12.5 12.5 12.5 12.5-5.6 12.5-12.5-5.6-12.5-12.5-12.5m0 20.6c-4.5 0-8.1-3.6-8.1-8.1s3.6-8.1 8.1-8.1 8.1 3.6 8.1 8.1c0 4.4-3.7 8.1-8.1 8.1" />
				<path fill="#FFFFFF" d="M40.2 11.3c0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9c1.5 0 2.9 1.3 2.9 2.9" />
			</g>
		</svg>
	)
}

export default IconInstagram
