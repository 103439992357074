var React = require('react')

function useTrigger (trigger, defaultVal) {
	var [val, set] = React.useState(defaultVal)

	React.useEffect(function () {
		trigger.subscribe(set)
		return function () { return trigger.unsubscribe(set) }
	}, [])

	return val
}

module.exports = useTrigger
