import React, { useState } from 'react'
import IconMenuClose from '../assets/IconMenuClose'

import styles from './PopupRoot.module.scss'

export const PopupContext = React.createContext({ showContent: () => {} })

// eslint-disable-next-line react/prop-types
const PopupRoot = ({ children }) => {
	const [content, setContent] = useState(<div />)
	const [visible, setVisible] = useState(false)

	function showContent(content) {
		setContent(content)
		setVisible(true)
	}

	function hideContent() {
		setVisible(false)
		setContent(<div />)
	}

	return <PopupContext.Provider value={{ showContent }}>
		{visible && <div className={styles.popup}>
			<IconMenuClose
				className={styles.closeButton}
				onClick={hideContent}
			/>
			{content}
		</div>}
		{children}
	</PopupContext.Provider>

}

export default PopupRoot
