/* eslint-disable react/prop-types */
import * as React from 'react'

const MenuButtonHome = ({ className }) =>
	<svg
		className={className}
		aria-labelledby="menu-button-home"
		height="39"
		role="img"
		viewBox="161 0 1 67"
		width="235"
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
	>
		<title id="menu-button-home">Menu Button Home</title>
		<path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.502" d="M186.1 58.7l-6.4-6.5 6.4-6.3-6.4-6.5 6.4-6.3-6.4-6.5 6.4-6.3-6.4-6.4 6.4-6.4-6.4-6.4-6.4 6.4-6.3-6.4-6.5 6.4-6.3-6.4-6.5 6.4-6.3-6.4-6.5 6.4 6.5 6.5-6.5 6.3 6.5 6.4-6.5 6.4 6.5 6.4-6.5 6.4 6.5 6.4-6.5 6.4 6.5 6.4 6.4-6.4 6.3 6.4 6.5-6.4 6.3 6.4 6.5-6.4 6.3 6.4z"/>
		<path d="M150.5 42c-1.2-.6-2.1-1.5-2.7-2.6s-1-2.4-1-3.8.3-2.7 1-3.8c.6-1.1 1.6-2 2.7-2.6 1.2-.6 2.5-.9 4-.9s2.8.3 4 .9c1.2.6 2.1 1.5 2.7 2.6.6 1.1 1 2.4 1 3.8s-.3 2.7-1 3.8c-.6 1.1-1.5 2-2.7 2.6-1.2.6-2.5.9-4 .9s-2.9-.3-4-.9zm6.8-1.7c.8-.5 1.5-1.1 1.9-1.9.5-.8.7-1.8.7-2.8 0-1-.2-2-.7-2.8-.5-.8-1.1-1.4-1.9-1.9s-1.8-.7-2.8-.7c-1.1 0-2 .2-2.8.7s-1.5 1.1-2 1.9c-.5.8-.7 1.7-.7 2.8 0 1.1.2 2 .7 2.8.5.8 1.1 1.4 2 1.9.8.5 1.8.7 2.8.7 1-.1 1.9-.3 2.8-.7zM167.4 30.5H165v-1.9h2.4v-1.1c0-3.2 1.4-4.8 4.2-4.8.9 0 1.8.1 2.6.4v1.8c-.8-.2-1.5-.4-2.3-.4-.9 0-1.5.2-1.9.7-.4.4-.5 1.2-.5 2.4v1h4.5v1.9h-4.5v12.1h-2.1V30.5zM192.9 22.7h15.2v2.4h-12.6v8.7h10.9v2.4h-10.9v8.4h-2.6V22.7zM227.3 39.4h-11l-2.4 5.3H211l9.9-22.2h1.6l10 22.2h-2.9l-2.3-5.3zm-7.5-8.1l-2.3 5.6h8.7l-2.3-5.6-2-4.7-2.1 4.7zM253.5 35c.9.9 1.3 2 1.3 3.4 0 2.1-.6 3.7-1.9 4.7-1.2 1-3.2 1.6-5.8 1.6h-10.2v-22h9.2c2.3 0 4.1.5 5.3 1.5 1.2 1 1.8 2.4 1.8 4.1 0 1.3-.3 2.3-.9 3.1-.6.8-1.4 1.4-2.4 1.7 1.6.4 2.8 1 3.6 1.9zm-14-2.8h6.1c1.7 0 2.9-.3 3.6-.9.8-.6 1.1-1.6 1.1-2.9 0-1.1-.4-1.9-1.1-2.5-.8-.5-1.8-.8-3.2-.8h-6.6v7.1zm11.4 9.1c.8-.6 1.2-1.6 1.2-2.9 0-2.5-1.9-3.7-5.8-3.7h-6.8v7.6h7.6c1.8-.1 3-.4 3.8-1zM274.1 44.6l-5.1-7.1h-6.5v7.1h-2.6v-22h8.7c2.6 0 4.6.6 6 1.9 1.4 1.3 2.1 3.1 2.1 5.5 0 1.8-.4 3.2-1.2 4.4-.8 1.2-2 2-3.6 2.5l5.5 7.6h-3.3zm-1.8-10.8c1.1-.9 1.6-2.1 1.6-3.7s-.5-2.9-1.4-3.7-2.4-1.3-4.2-1.3h-5.7v10h5c2 .1 3.6-.4 4.7-1.3zM282.5 22.7h2.7v22h-2.7v-22zM296.1 43.6c-1.7-.9-3-2.3-3.9-4-.9-1.7-1.4-3.7-1.4-6.1 0-2.3.5-4.3 1.4-6 .9-1.7 2.2-3 3.9-3.9 1.7-.9 3.6-1.4 5.9-1.4 4 0 7.2 1.4 9.4 4.2l-2.1 1.7c-.8-1.1-1.8-1.9-3.1-2.6-1.3-.6-2.7-.9-4.3-.9-1.7 0-3.1.4-4.4 1.1-1.3.7-2.3 1.7-3 3.1-.7 1.3-1.1 2.9-1.1 4.7 0 1.8.4 3.4 1.1 4.7.7 1.4 1.7 2.4 3 3.2 1.3.7 2.7 1.1 4.4 1.1 1.6 0 3-.3 4.3-.9 1.3-.6 2.3-1.5 3.1-2.5l2.1 1.7c-2.3 2.8-5.4 4.2-9.4 4.2-2.2 0-4.2-.4-5.9-1.4z" fill="#fff" />
		<g>
			<path d="M0 22.7h15.2v2.4H2.6v8.7h10.9v2.4H2.6v8.4H0V22.7zM22.3 42.8c-1.5-1.5-2.2-3.8-2.2-6.8V22.7h2.6v12.7c0 2.5.5 4.3 1.5 5.4 1 1.1 2.6 1.7 4.9 1.7 2.3 0 3.9-.6 4.9-1.7 1-1.1 1.5-2.9 1.5-5.4V22.7h2.6V36c0 3-.7 5.3-2.2 6.8-1.5 1.5-3.7 2.3-6.8 2.3-3-.1-5.3-.8-6.8-2.3zM50.4 25.1h-7.5v-2.4h17.8v2.4h-7.5v19.5h-2.7V25.1zM67.6 42.8c-1.5-1.5-2.2-3.8-2.2-6.8V22.7H68v12.7c0 2.5.5 4.3 1.5 5.4 1 1.1 2.6 1.7 4.9 1.7 2.3 0 3.9-.6 4.9-1.7 1-1.1 1.5-2.9 1.5-5.4V22.7h2.6V36c0 3-.7 5.3-2.2 6.8-1.5 1.5-3.7 2.3-6.8 2.3-3-.1-5.3-.8-6.8-2.3zM103.9 44.6l-5.1-7.1h-6.5v7.1h-2.6v-22h8.7c2.6 0 4.6.6 6 1.9 1.4 1.3 2.1 3.1 2.1 5.5 0 1.8-.4 3.2-1.2 4.4-.8 1.2-2 2-3.6 2.5l5.5 7.6h-3.3zm-1.8-10.8c1.1-.9 1.6-2.1 1.6-3.7s-.5-2.9-1.4-3.7-2.4-1.3-4.2-1.3h-5.7v10h5c2 .1 3.6-.4 4.7-1.3z" fill="#fff" />
		</g>
		<g>
			<path d="M112.1 22.7H127v2.4h-12.3v7.4h10.4V35h-10.4v7.3h13v2.4h-15.6v-22z" fill="#fff" />
		</g>
	</svg>

export default MenuButtonHome


