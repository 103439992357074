export const hideWelcomeText = 750

export const showIntroText1 = hideWelcomeText + 1150
export const hideIntroText1 = showIntroText1 + 600

export const showIntroText2 = hideIntroText1 + 200
export const hideIntroText2 = showIntroText2 + 600

export const showIntroText3 = hideIntroText2 + 200
export const hideIntroText3 = showIntroText3 + 600

export const showIntroText4 = hideIntroText3 + 200
export const hideIntroText4 = showIntroText4 + 600

export const showGlobe = hideIntroText4 + 600
export const hideLocations = showGlobe + 600
export const showProfiles = hideLocations + 200
export const skewProfiles = showProfiles + 600

export const showScrollIcon = 0
export const hideScrollIcon = hideLocations + 200

export const introBackgroundVideoPlaying = 0
export const introBackgroundVideoPaused = showIntroText1

export const end = skewProfiles + 500
