import * as React from 'react'

const IconMenu = () => {
	return (
		<svg
			aria-labelledby="icon-menu"
			height="57.5"
			role="img"
			viewBox="0 0 70.9 57.5"
			width="70.9"
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
		>
			<title id="icon-menu">Icon Menu</title>
			<path fill="transparent" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M2 13.2L13 2l11.3 11.2L35.3 2l11.3 11.2L57.6 2l11.3 11.2M2 34.4l11-11.2 11.3 11.2 11-11.2 11.3 11.2 11-11.2 11.3 11.2M2 55.5l11-11.1 11.3 11.1 11-11.1 11.3 11.1 11-11.1 11.3 11.1" />
		</svg>
	)
}

export default IconMenu


